export const colours = {
  // Brand Colours
  primary: '#0049FF',
  secondary: '#0b3d7f',
  secondaryAlternative: '#0b3d7f',
  third: '#1479ff',

  // State Colours
  success: '#00d992',
  error: '#ff3f2f',
  warning: '#fff58a',
  answer: '#fca00a',

  // Ranking
  gold: '#ec9922',
  silver: '#c1c1c1',
  bronze: '#d26704',

  // Neutrals
  text: '#111',
  background: '#fff',
  mainBackground: '#f5f7ff',

  // Shades of Gray
  gray1: '#555',
  gray2: '#999',
  gray3: '#ccc',
  gray4: '#eee',

  // Emphasis
  emphasis: '#00d992',

  // Colored Grays
  purpleGray: '#e0edff',
  darkPurpleGray: '#a6a6be',

  // ContentLoadingColors
  contentLoadingColor1: 'rgba(184, 185, 191, .2)',
  contentLoadingColor2: 'rgba(122, 123, 128, .2)',
  contentLoadingColor3: 'rgba(202, 213, 230, .2)',
  contentLoadingColor4: 'rgba(179, 190, 204, .2)',

  // live
  transmissions: '#fc3626',
  transmissions2: '#f55f53',

  // Class Relevance
  veryLow: '#A6A6BE',
  low: '#F9B800',
  average: '#FF8345',
  high: '#DB2E2E',
  watched: '#1477FF',
  backBar: '#D5E5FF',

  // Discipline Colours
  linguagens: '#20BF6B',
  'biologia-enem-e-vestibulares': '#079992',
  'analise-de-temas-de-redacao': '#20BF6B',
  matematica: '#E55039',
  fisica: '#4A69BD',
  'fisica-(prof.-boaro)': '#4A69BD',
  'fisica-(prof.-coelho)': '#4A69BD',
  quimica: '#00B5EE',
  'biologia-(prof.-landim)': '#079992',
  biologia: '#079992',
  'biologia-(prof.-samuel)': '#079992',
  'biologia-para-o-enem': '#079992',
  historia: '#F19066',
  'historia-do-brasil': '#F19066',
  'historia-da-arte': '#F19066',
  geografia: '#78E08F',
  filosofiasociologia: '#DE6C43',
  filosofia: '#CF6A87',
  sociologia: '#EE6F00',
  gramatica: '#20BF6B',
  'interpretacao-de-texto': '#20BF6B',
  literatura: '#20BF6B',
  atualidades: '#778BEB',
  redacao: '#20BF6B',
  ingles: '#1E3799',
  espanhol: '#F1BF00',
  'sala-dos-professores-(lives)': '#808080',
  'avenger-produtividade-foco-e-disciplina': '#303952',
  'historia.': '#F19066',
  'ciencias-da-natureza': '#78E08F',
  'ciencias-humanas': '#079992',

  // Beta Version
  betaTextWarning: '#714412',
  betaSurfaceWarning: '#FEFCC3',
}

export const coloursDark = {
  primary: '#0049FF',
  primaryBlue: '#0049FF',
  secondary: '#434343',
  third: '#1479ff',

  // State Colours
  success: '#00d992',
  error: '#ff3f2f',
  warning: '#fff58a',
  answer: '#fca00a',

  // Ranking
  gold: '#ec9922',
  silver: '#c1c1c1',
  bronze: '#d26704',

  background: '#292929',
  background2: '#343434',
  mainBackground: '#1e1e1e',
  text: '#F0F0F0',
  textSecondary: '#CACACA',

  // Shades of Gray
  gray1: '#555',
  gray2: '#999',
  gray3: '#ccc',
  gray4: '#eee',

  // Emphasis
  emphasis: '#00d992',

  // Colored Grays
  purpleGray: '#e0edff',
  darkPurpleGray: '#d9d9d9',

  // ContentLoadingColors
  contentLoadingColor1: 'rgba(184, 185, 191, .2)',
  contentLoadingColor2: 'rgba(122, 123, 128, .2)',
  contentLoadingColor3: 'rgba(202, 213, 230, .2)',
  contentLoadingColor4: 'rgba(179, 190, 204, .2)',

  // live
  transmissions: '#fc3626',
  transmissions2: '#f55f53',

  // Class Relevance
  watched: '#0049FF',
  backBar: '#6E778F',

  // Beta Version
  betaTextWarning: '#FEFDE8',
  betaSurfaceWarning: '#714412',
}

export const radius = {
  small: '10px',
  medium: '15px',
  large: '20px',
}

export const zIndex = {
  hidden: -1,
  base: 10,
  middle: 20,
  top: 30,
  aboveAll: 40,
}

export const theme = {
  font: {
    default: 'Montserrat, Arial, Helvetica, sans-serif',
  },
  colours: { ...colours },
  radius,
  zIndex,
}

export const darkTheme = {
  font: {
    default: 'Montserrat, Arial, Helvetica, sans-serif',
  },
  colours: { ...coloursDark },
  radius,
  zIndex,
  darkTheme: true,
}
